


























































































import { defineComponent, toRefs, computed } from '@vue/composition-api'
import { useNavbar } from '../hook/useNavbar'
import { coreMenu, newsMenu } from '../constants/index'

export default defineComponent({
  name: 'MobileNavbar',
  setup() {
    const { state, handleClick, handleCollapse, handleOpen } = useNavbar()

    const darkMode = computed(() => state.mode === 'dark')

    return {
      ...toRefs(state),
      darkMode,
      coreMenu,
      newsMenu,
      handleOpen,
      handleClick,
      handleCollapse
    }
  }
})
