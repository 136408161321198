import { menuModel } from '@/types/pages'

const coreMenu: menuModel[] = [
  {
    key: 'zhzy',
    value: '高质量作业',
    link: '/zhzy'
  },
  {
    key: 'khfw',
    value: '云思课后服务',
    link: '/khfw'
  },
  {
    key: 'zhty',
    value: '智慧体育',
    link: '/zhty'
  },
  {
    key: 'zhkt',
    value: '智慧课堂',
    link: '/zhkt'
  }
]
const newsMenu: menuModel[] = [
  {
    key: 'news',
    value: '公司新闻',
    link: '/news?type=news'
  },
  {
    key: 'media',
    value: '媒体报道',
    link: '/news?type=media'
  }
]

export { coreMenu, newsMenu }
